
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Resizable } from 'react-resizable';

import 'react-resizable/css/styles.css';
import './CropInspector.css';

import Parse from 'parse';

Parse.initialize(
  "7eAsVaNNrFmJ7lVQU81L5J93HN2625ql"
);

Parse.serverURL = "https://prod.curtsyapp.com/parse";

const ImageTrainingData = Parse.Object.extend("ImageTrainingData");
const imageKitRoot = "https://ik.imagekit.io/pi9lsweb7gk/";

const parseImageToImageKit = (url, width = 250) => {
  let parts = url.split(".com/"),
    filename = parts[parts.length-1];
  return `${imageKitRoot}/tr:w-${width},h-${width},c-at_max/${filename}`
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function enforceMinBoundingBox(bbox) {
  const [xmin, xmax, ymin, ymax] = bbox;

  return [
    xmin <= 0.035 ? 0 : xmin,
    xmax >= 0.965 ? 1 : xmax,
    ymin <= 0.035 ? 0 : ymin,
    ymax > 0.965 ? 1 : ymax,
  ];
}


const ImageWithOverlay = ({ src, id, xmin, xmax, ymin, ymax }) => {

  const imgRef = useRef();
  const containerRef = useRef(null);
  const [imgDimensions, setImgDimensions] = useState({});
  const [overlayDimensions, setOverlayDimensions] = useState({width: 0, height: 0});
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionStart, setSelectionStart] = useState({ x: 0, y: 0 });
  const padding = 50;

  useEffect(() => {
    const adjustedLeft = overlayDimensions.left - padding;
    const adjustedTop = overlayDimensions.top - padding;
    const adjustedWidth = overlayDimensions.width;
    const adjustedHeight = overlayDimensions.height;

    const xmin = (adjustedLeft / imgDimensions.width).toFixed(2);
    const xmax = ((adjustedLeft + adjustedWidth) / imgDimensions.width).toFixed(2);
    const ymin = (adjustedTop / imgDimensions.height).toFixed(2);
    const ymax = ((adjustedTop + adjustedHeight) / imgDimensions.height).toFixed(2);

    // console.log(`xmax: ${xmax}, xmin: ${xmin}, ymax: ${ymax}, ymin: ${ymin}`);
  
  }, [overlayDimensions, imgDimensions, padding]);


  useEffect(() => {
    if (imgRef.current) {
      setImgDimensions({
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    // Calculate the width and height from the provided values
    const width = (xmax - xmin) * imgDimensions.width;
    const height = (ymax - ymin) * imgDimensions.height;

    // Define the dimensions for the overlay
    setOverlayDimensions({
      top: ymin * imgDimensions.height + padding,
      left: xmin * imgDimensions.width + padding,
      width: width,
      height: height,
    });
  }, [imgDimensions, xmin, xmax, ymin, ymax]);


  const handleMouseDown = (e) => {
    e.preventDefault();

    const isResizeHandle = e.target.classList.contains('react-resizable-handle');

    if (isResizeHandle) {
      return;
    }

    setIsSelecting(true);
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top ;
    setSelectionStart({ x, y });
  };
  
  const handleMouseMove = (e) => {
    if (!isSelecting) return;
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const newWidth = Math.min(imgDimensions.width - overlayDimensions.left + padding, Math.abs(selectionStart.x - x));
    const newHeight = Math.min(imgDimensions.height - overlayDimensions.top + padding, Math.abs(selectionStart.y - y));
  
    setOverlayDimensions({
      left: Math.max(padding, Math.min(selectionStart.x, x)),
      top: Math.max(padding, Math.min(selectionStart.y, y)),
      width: newWidth,
      height: newHeight
    });

  };

  const handleMouseUp = () => {
    setIsSelecting(false);
    saveBoundingBox();

  };

    // Define the styles for the overlay
    const overlayStyles = {
      position: 'absolute',
      top: `${overlayDimensions.top}px`,
      left: `${overlayDimensions.left}px`,
      boxShadow: `inset 0px 0px 0px 1px rgba(255,0,0,.5)`,
      backgroundColor: 'rgba(255, 0, 0, 0.05)', // Transparent red
      zIndex: 1,
    };

  const resizeHandler = (event, { size, handle }) => {
    const deltaX = overlayDimensions.width - size.width; // change in width
    const deltaY = overlayDimensions.height - size.height; // change in height
    
    const isNorth = handle[0] === 'n';
    const isWest = handle[handle.length - 1] === 'w';
  
    const newTop = isNorth ? Math.max(padding, overlayDimensions.top + deltaY) : overlayDimensions.top;
    const newLeft = isWest ? Math.max(padding, overlayDimensions.left + deltaX) : overlayDimensions.left;
    
    const newWidth = Math.min(size.width, isWest ? overlayDimensions.left + overlayDimensions.width - padding : imgDimensions.width - newLeft + padding);
    const newHeight = Math.min(size.height, isNorth ? overlayDimensions.top + overlayDimensions.height - padding : imgDimensions.height - newTop + padding);
  
    setOverlayDimensions({
      top: newTop,
      left: newLeft,
      width: newWidth,
      height: newHeight,
    });
  }

  const saveBoundingBox = async () => {
    const adjustedLeft = overlayDimensions.left - padding;
    const adjustedTop = overlayDimensions.top - padding;
    const adjustedWidth = overlayDimensions.width;
    const adjustedHeight = overlayDimensions.height;

    const xmin = (adjustedLeft / imgDimensions.width);
    const xmax = ((adjustedLeft + adjustedWidth) / imgDimensions.width);
    const ymin = (adjustedTop / imgDimensions.height);
    const ymax = ((adjustedTop + adjustedHeight) / imgDimensions.height);

    let itr = new ImageTrainingData();
    itr.id = id;
    itr.set("boundingBox", [xmin, xmax, ymin, ymax]);
    await itr.save();

    console.log(`xmax: ${xmax.toFixed(2)}, xmin: ${xmin.toFixed(2)}, ymax: ${ymax.toFixed(2)}, ymin: ${ymin.toFixed(2)}`);
  };

  // Handler to clear the selection
  const handleClearSelection = async () => {
    setOverlayDimensions({width: 0, height: 0});
    let itr = new ImageTrainingData();
    itr.id = id;
    itr.unset("boundingBox");
    itr.unset("predictedBBox");
    await itr.save();
  };


  const handleResizeStop = (event, { size, handle }) => {
    resizeHandler(event, { size, handle });
    saveBoundingBox();
  }

  // Define the styles for the container
  const containerStyles = {
    position: 'relative',
    display: 'inline-block',
    padding: `${padding}px`
  };

  return (
    <div>
    <div style={containerStyles}
    onMouseDown={handleMouseDown}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
    className="container"
    ref={containerRef}
    >

      <img ref={imgRef} src={src}
      alt="Some description" style={{display: 'block'}} onLoad={() => setImgDimensions({
        width: imgRef.current.offsetWidth,
        height: imgRef.current.offsetHeight,
      })} />
      {imgDimensions.width && imgDimensions.height && (
        <Resizable
          width={overlayDimensions.width}
          height={overlayDimensions.height}
          resizeHandles={['sw', 'nw', 'se', 'ne', "s", "n", "e", "w"]}
          onResize={resizeHandler}
          onResizeStop={handleResizeStop}
         >
          <div style={{...overlayStyles, width: `${overlayDimensions.width}px`, height: `${overlayDimensions.height}px`}} />
        </Resizable>
      )}
      </div>
      {overlayDimensions.width && overlayDimensions.height && <button onClick={handleClearSelection}>Clear Selection</button>}
      {id}


    </div>
  );

};

const CropInspector = ({source}) => {

  const [images, setImages] = useState([]);

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = Number(query.get('page') || '1');
  const datasetId = query.get('datasetId');
  const label = query.get('label');
  const boundingBoxExists = query.get('boundingBoxExists') === "true";

  useEffect(() => {
    const fetchImages = async () => {
      try {
        if (source === "default") { 
          const query = new Parse.Query("ImagePredictionResult");
          query.descending('createdAt'); // Sort by the most recent images
          query.exists('preCroppedUrl'); // Sort by the most recent images
          // query.ascending('predictedBBoxConfidence'); // Sort by the most recent images
          query.equalTo('autoCropPerformed', true); // Sort by the most recent images
          // query.lessThan('excessPartsNoExcess', 0.5); // Sort by the most recent images
          query.include('image'); // Sort by the most recent images
          // query.lessThanOrEqualTo("predictedBBoxConfidence", 0.99);

          query.skip((currentPage - 1) * 100);
          query.limit(100);

          let results = await query.find();
          console.log(results.length);

          let imagesData = results.map(ipr => {

            try {

            let image = ipr.get("image");
            let bbox = ipr.get('predictedBBox') || [];
            bbox = enforceMinBoundingBox(bbox);
            if (!image) return false;

              return {
                id: ipr.id,
                url: parseImageToImageKit(ipr.get('preCroppedUrl')),
                bbox: bbox,
                formattedBBox: "[" + bbox.map( n => n === 1 ? 1 : n === 0 ? 0 : n.toFixed(2)  ).join(",") + "]",
                confidence: ipr.get("predictedBBoxConfidence").toFixed(5)
              }
            } catch(e){ 
              return false;
            }

          });
          imagesData = imagesData.filter( i => i );

          setImages(imagesData);
        } else if (source === "training") {
          const query = new Parse.Query("ImageTrainingData");

          if (label) query.equalTo("label", label);
          if (datasetId) query.equalTo("datasetId", datasetId);
          query.descending('predictedBBoxConfidence'); // Sort by the most recent images

          if (boundingBoxExists) {
            query.exists('boundingBox'); 
          } else {
            query.exists('predictedBBox'); 
            query.doesNotExist('boundingBox'); 
          }

          query.skip((currentPage - 1) * 100);
          query.limit(100);

          let results = await query.find();
          let imagesData = results.map(itr => {

            try {

              let bbox = itr.get("boundingBox") || itr.get('predictedBBox') || [];

              // fix values under 0 and above 1
              if (bbox[0]) bbox[0] = Math.max(bbox[0], 0);
              if (bbox[1]) bbox[1] = Math.min(bbox[1], 1);
              if (bbox[2]) bbox[2] = Math.max(bbox[2], 0);
              if (bbox[3]) bbox[3] = Math.min(bbox[3], 1);

              return {
                id: itr.id,
                url: parseImageToImageKit(itr.get('url'), 512),
                bbox: bbox,
                formattedBBox: "[" + bbox.map( n => n === 1 ? 1 : n === 0 ? 0 : n.toFixed(2)  ).join(",") + "]",
                confidence: itr.get("predictedBBoxConfidence")?.toFixed(5)
              }
            } catch(e){ 
              return false;
            }

          });

          imagesData = imagesData.filter( i => i );
          setImages(imagesData);

        }

      } catch (error) {
        console.error('Error fetching images:', error);
      }
    }
    fetchImages();

  }, [currentPage]);

  const onNextPage = () => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("page", currentPage+1);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };

  return (
    <div>
        <div className={`image-grid ${source === 'training' ? 'large' : ''}`}>
      {images.map((image) => (
        <div key={image.id}>
          <ImageWithOverlay
            src={image.url}
            id={image.id}
            xmin={image.bbox[0]}
            xmax={image.bbox[1]}
            ymin={image.bbox[2]}
            ymax={image.bbox[3]}
          />
          {image.confidence} <br /> {image.formattedBBox}
        </div>
      ))}

    </div>
    <button className="next-btn" onClick={onNextPage}>Next</button>
    </div>

  );

}


export default CropInspector;
