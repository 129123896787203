import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ImagesPage from './ImagesPage';
import HomePage from './HomePage';
import CropInspector from './CropInspector';
import Recs from './Recs';

function App() {
  return (
    <Routes>
      <Route path="/images" element={<ImagesPage />} />
      <Route path="/" element={<HomePage />} />
      <Route path="/crop" element={ <CropInspector source="default" />} />
      <Route path="/crop-training" element={ <CropInspector source="training" />} />
      <Route path="/recs" element={ <Recs />} />
    </Routes>
  );
}

export default App;