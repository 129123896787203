import React, { useState } from "react";
import axios from 'axios';

const query = (userId) => { 
  return `
  WITH
  deduped_loves AS (
  SELECT
    love._p_user,
    love._p_dress,
    MAX(love._created_at) AS _created_at,
  FROM
    mongo_curtsy_live.love love
  INNER JOIN
    mongo_curtsy_live.dress dress
  ON
    REPLACE(_p_dress, "Dress$","") = dress._id
    AND dress._fivetran_deleted != TRUE
  LEFT JOIN
    mongo_curtsy_live.love copy_love
  ON
    dress._p_copy_of = copy_love._p_dress
    AND love._p_user = copy_love._p_user
  WHERE
    love._fivetran_deleted != TRUE
    AND copy_love._id IS NULL
  GROUP BY
    1,
    2),
  item_style_data AS (
  SELECT
    _id AS dress_id,
    style,
    cleaned,
    num_available,
    _rank AS style_score,
    num_dresses
  FROM (
    SELECT
      *
    FROM (
      SELECT
        _id,
        REPLACE(x, '"','') AS style,
        LENGTH(REGEXP_REPLACE(x, r'[^\s]','')) + 1 word_count
      FROM
        mongo_curtsy_live.dress,
        UNNEST (JSON_EXTRACT_ARRAY(styles_v_2)) AS x )
    WHERE
      word_count > 1)
  LEFT JOIN (
    SELECT
      _id AS style,
      cleaned,
      ref_count
    FROM
      mongo_curtsy_live.known_style_v_3)
  USING
    (style)
  LEFT JOIN
    scheduled_stats.known_style_stats_v3
  USING
    (style)
  WHERE
    ref_count >= 3
  ORDER BY
    _id ASC ),
  active_users AS (
  SELECT
    "${userId}" AS user_id ),
  loves AS (
  SELECT
    REPLACE(_p_dress, "Dress$", "") AS dress_id,
    REPLACE(_p_user, "_User$","") AS user_id,
    _created_at AS timestamp,
    "love" AS type
  FROM
    deduped_loves
  WHERE
    _created_at > TIMESTAMP_SUB(CURRENT_TIMESTAMP(), INTERVAL 30 day)
    AND REPLACE(_p_user, "_User$", "") IN (
    SELECT
      *
    FROM
      active_users) ),
  clicks AS (
  SELECT
    product_id AS dress_id,
    user_id,
    timestamp,
    "click" AS type
  FROM
    ios.product_viewed
  WHERE
    _PARTITIONDATE > DATE_SUB(CURRENT_DATE(), INTERVAL 31 day)
    AND timestamp > TIMESTAMP_SUB(CURRENT_TIMESTAMP(), INTERVAL 30 day)
    AND user_id != seller_id
    AND user_id IN (
    SELECT
      *
    FROM
      active_users)),
  all_interactions AS (
  SELECT
    *
  FROM
    loves
  UNION ALL
  SELECT
    *
  FROM
    clicks ),
  deduped_interactions AS (
  SELECT
    dress_id,
    user_id,
    score,
    timestamp,
    type
  FROM (
    SELECT
      *,
      ROW_NUMBER() OVER (PARTITION BY user_id, dress_id ORDER BY score DESC, timestamp DESC) rank_
    FROM (
      SELECT
        dress_id,
        user_id,
        type,
      IF
        (type="click", 1,
        IF
          (type="love", 10, NULL)) AS score,
        timestamp
      FROM
        all_interactions ) )
  WHERE
    rank_ = 1 ),
  interactions_plus_styles AS (
  SELECT
    *,
  FROM
    deduped_interactions
  INNER JOIN
    item_style_data
  USING
    (dress_id) )
SELECT
  interaction_score_bin + recency_bin  + specificity_bin as score,
  *
FROM (
  SELECT
    FLOOR(ROW_NUMBER() OVER (PARTITION BY user_id ORDER BY num_available_style DESC) / (user_style_count / 3)) AS specificity_bin,
    FLOOR(ROW_NUMBER() OVER (PARTITION BY user_id ORDER BY most_recent_interaction) / (user_style_count / 6)) AS recency_bin,
    FLOOR(ROW_NUMBER() OVER (PARTITION BY user_id ORDER BY total_score) / (user_style_count / 9)) AS interaction_score_bin,
    *
  FROM (
    SELECT
      *,
      COUNT(*) OVER (PARTITION BY user_id) AS user_style_count,
    FROM (
      SELECT
        style,
        user_id,
        MIN(cleaned) AS cleaned,
        SUM(score) AS total_score,
        COUNTIF(type="love") love_ct,
        count(distinct dress_id) items,
        COUNTIF(type="click") click_ct,
        MAX(timestamp) most_recent_interaction,
        MIN(num_available) AS num_available_style,
        MIN(style_score) style_score,
        MIN(num_dresses) num_dresses_style
      FROM
        interactions_plus_styles
      GROUP BY
        style,
        user_id
      ORDER BY
        3 DESC )
    WHERE
      items > 1 ))
ORDER BY
  1 DESC
  -- meaningful_style_interactions_only AS (
  -- SELECT
  --   style,
  --   user_id,
  --   cleaned,
  --   num_dresses,
  --   num_available_style,
  --   most_recent_interaction,
  --   interaction_score_adj,
  --   post_count,click_count, love_count,
  --   COUNT(*) OVER (PARTITION BY user_id) user_style_count
  -- FROM (
  --   SELECT
  --     *
  --   FROM (
  --     SELECT
  --       *,
  --       interaction_score_adj / num_dresses AS interaction_score_divided_by_items,
  --       PERCENTILE_CONT(interaction_score_adj/ num_dresses, 0.75) OVER (PARTITION BY user_id) AS p75
  --     FROM (
  --       SELECT
  --         style,
  --         user_id,
  --         MIN(cleaned) AS cleaned,
  --         COUNT(DISTINCT dress_id) AS num_dresses,
  --         COUNT(DISTINCT TIMESTAMP_TRUNC(timestamp, day)) num_days_interacted,
  --         MAX(timestamp) AS most_recent_interaction,
  --         MAX(num_available) AS num_available_style,
  --         SUM(interaction_score_adj) AS interaction_score_adj,
  --         countif(type="post") as post_count,
  --         countif(type="love") as love_count,
  --         countif(type="click") as click_count
  --       FROM (
  --         SELECT
  --           *
  --         FROM (
  --           SELECT
  --             *,
  --             interaction_score / MAX(rank_) OVER (PARTITION BY user_id, dress_id) AS interaction_score_adj
  --           FROM
  --             interactions_plus_styles) )
  --       GROUP BY
  --         1,
  --         2 ) )
  --   WHERE
  --     interaction_score_divided_by_items >= p75 ))
 `;
}

function normalizeScores(data) {
  if (!Array.isArray(data) || data.length === 0) return [];

  const scores = data.map(obj => obj.score);
  const minScore = Math.min(...scores);
  const maxScore = Math.max(...scores);
  const scoreRange = maxScore - minScore || 1;

  return data.map(obj => ({
    ...obj,
    scoreBackup: obj.score,
    score: (obj.score - minScore) / scoreRange,
  }));
}


function calculateSimilarity(obj1, obj2) {
  const words1 = new Set(obj1.style.split(' '));
  const words2 = new Set(obj2.style.split(' '));
  const intersection = new Set([...words1].filter(word => words2.has(word)));
  const union = new Set([...words1, ...words2]);
  const jaccard = intersection.size / union.size;
  const numberDifference = Math.abs(obj1.score - obj2.score);

  return 0.5 * jaccard + 0.5 * (1 - numberDifference);
}
function calculateAverageOfObjectsProperty(data, key) {
  if (!Array.isArray(data) || data.length === 0) return 0;

  const sum = data.reduce((total, obj) => total + obj[key], 0);
  const average = sum / data.length;

  return average;
}


function hierarchicalClustering(objects, similarityThreshold) {
  const clusters = objects.map(obj => [obj]);

  while (clusters.length > 1) {
    let maxSimilarity = -1;
    let mergePair = [-1, -1];

    for (let i = 0; i < clusters.length; i++) {
      for (let j = i + 1; j < clusters.length; j++) {
        let similaritySum = 0;
        let count = 0;

        for (const obj1 of clusters[i]) {
          for (const obj2 of clusters[j]) {
            similaritySum += calculateSimilarity(obj1, obj2);
            count++;
          }
        }

        const averageSimilarity = similaritySum / count;
        if (averageSimilarity > maxSimilarity) {
          maxSimilarity = averageSimilarity;
          mergePair = [i, j];
        }
      }
    }

    if (maxSimilarity >= similarityThreshold) {
      const mergedCluster = clusters[mergePair[0]].concat(clusters[mergePair[1]]);
      clusters.splice(mergePair[1], 1);
      clusters.splice(mergePair[0], 1);
      clusters.push(mergedCluster);
    } else {
      break;
    }
  }

  return clusters;
}

const similarityThreshold = 0.55;


  function App() {
    const [input, setInput] = useState('');
    const [queryResult, setQueryResult] = useState(null);
  
    const runQuery = async () => {

      let _query = query(input);
      try {
        const res = await axios.post('http://localhost:1337/query', { sql: _query });
        console.log(res);
        let styles = normalizeScores(res.data);
        let clusters = hierarchicalClustering(styles, similarityThreshold);

        clusters = clusters.sort((a, b) => {
          return calculateAverageOfObjectsProperty(b,"scoreBackup") - 
                calculateAverageOfObjectsProperty(a, "scoreBackup");
        }).map( c => {
          return c.map( s =>  { s.score = s.scoreBackup; delete s.scoreBackup; return s; });
        }).map(c => c.map( c => c.cleaned ));

        setQueryResult(JSON.stringify(clusters, null, 2));

      } catch(err) {
        setQueryResult(`Error: ${err.message}`);
      }
    };
  
    return (
      <div>
        <input type="text" value={input} onChange={e => setInput(e.target.value)} />
        <button onClick={runQuery}>Run Query</button>
        {queryResult && <pre>{queryResult}</pre>}
      </div>
    );
  }

export default App;
