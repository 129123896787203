import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Images.css';

import Parse from 'parse';

Parse.initialize(
  "7eAsVaNNrFmJ7lVQU81L5J93HN2625ql"
);

Parse.serverURL = "https://prod.curtsyapp.com/parse";
const ImageTrainingData = Parse.Object.extend('ImageTrainingData'); // Replace 'Image' with your Parse class name for images

const imageKitRoot = "https://ik.imagekit.io/pi9lsweb7gk/";

const PAGE_SIZE = 100; // Adjust this value to set the number of images per page

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const parseImageToImageKit = (url,lowres) => {
  let parts = url.split(".com/"),
    filename = parts[parts.length-1],
    dimensions = lowres ? 256 : 512;

  return `${imageKitRoot}/tr:w-${dimensions},h-${dimensions},c-at_max/${filename}`
}

const fetchDistinctLabelsForDatasetId = async (datasetId) => {
  try {
    let results = await Parse.Cloud.run("getDistinctLabelsForImageTrainingDatasetId", {
      datasetId
    });
    return results;
  } catch (error) {
    throw new Error(error);
  }
}

function ImagesPage() {
  console.log("Test");
  const [images, setImages] = useState([]);
  const [totalImages, setTotalImages] = useState(0);
  const [datasetIds, setDatasetIds] = useState([]);
  const [labels, setLabels] = useState([]);

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = parseInt(query.get('page') || '1', 10);

  const datasetId = query.get('datasetId') || '';
  const label = query.get('label') || '';
  const showOmitted = query.get('showOmitted') === 'true';
  const showIncluded = query.get('showIncluded') === 'true';
  const showPredictionsOnly = query.get('showPredictionsOnly') === 'true';
  const predictionId = query.get('predictionId');
  const predictionMinMax = query.get('predictionMinMax')?.split("-")?.map(s => Number(s));

  const confFilter = query.get('confFilter');
  const sortBy = query.get('sortBy');
  const sortDirection = query.get('sortDirection') ? Number(query.get("sortDirection")) : null;
  const confMinMax = query.get('confMinMax')?.split("-")?.map(s => Number(s));

  console.log(predictionId, predictionMinMax);

  const [selectedImages, setSelectedImages] = useState(new Set());

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalDatasetId, setModalDatasetId] = useState('');
  const [modalLabel, setModalLabel] = useState('');
  const [modalLabels, setModalLabels] = useState('');
  const [modalOmit, setModalOmit] = useState(false);
  const [modalInclude, setModalInclude] = useState(false);


  const handleDatasetIdChange = (e) => {
    const newDatasetId = e.target.value;
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("datasetId", newDatasetId);
    urlParams.delete("label");
    urlParams.delete("page");
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };
  
  const handleLabelChange = (e) => {
    const newLabel = e.target.value;
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("label", newLabel);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };


  const fetchDatasetIds = useCallback(async () => {
    try {
      let results = await Parse.Cloud.run("getDistinctSansMasterKey", {
        _class: "ImageTrainingData",
        field: "datasetId"
      });
      setDatasetIds(results);
    } catch (error) {
      console.error('Error while fetching datasetIds:', error);
    }
  }, []);
  

  const fetchLabels = useCallback(async () => {
    try {
      setLabels(
        [...await fetchDistinctLabelsForDatasetId(datasetId), "none"]
      )
    } catch (error) {
      console.error('Error while fetching labels:', error);
    }
  }, [datasetId]);

  const handleModalDatasetChange = async (datasetId) => {
    setModalDatasetId(datasetId);
    try {
      setModalLabels(await fetchDistinctLabelsForDatasetId(datasetId));
    } catch(e) {
      console.log("Error fetching labels for modal datasetId selection", e);
    }
  }

  const handleCreateNewLabel = () => {
    const newLabel = window.prompt('Enter the new label name:');
    if (newLabel) {
      setModalLabels([...modalLabels, newLabel]);
      setModalLabel(newLabel);
    }
  };
  
  const handleCreateNewDataset = () => {
    const newDataset = window.prompt('Enter the new dataset name:');
    if (newDataset) {
      setDatasetIds([...datasetIds, newDataset]);
      setModalDatasetId(newDataset);
    }
  };
  
  const fetchTotalImages = useCallback(async () => {
      const query = new Parse.Query(ImageTrainingData);
      query.equalTo("datasetId", datasetId);
      query.exists("url");

      if (label) {
        if (label==="none"){
          query.doesNotExist("label");
        } else {
          query.equalTo("label", label);
        }
      }

      if (predictionId && predictionMinMax) {
        if (predictionMinMax?.length === 2) {
          let innerQuery = new Parse.Query("ImagePredictionResult");
          innerQuery.exists(predictionId);
          innerQuery.greaterThanOrEqualTo(predictionId, predictionMinMax[0]);
          innerQuery.lessThanOrEqualTo(predictionId, predictionMinMax[1]);
          query.matchesQuery("predictionData", innerQuery);
        }
      }

      if (showOmitted) {
        query.equalTo('omit', true);
      }

      if (sortBy) {
        query.exists(sortBy);
      }

      if (confFilter) {
        query.exists(confFilter);
      }

      if (confMinMax && confMinMax.length) {
        if (confMinMax[0]) {
          query.greaterThanOrEqualTo(confFilter, confMinMax[0]);
        }
        if (confMinMax[1]) {
          query.lessThan(confFilter, confMinMax[1]);
        }
      }

      if (showPredictionsOnly) {
        query.exists('predictionData');
      }

      if (showPredictionsOnly) {
        query.exists('predictionData');
      }

      if (showIncluded) {
        query.equalTo('include', true);
      }

      try {
        const count = await query.count();
        setTotalImages(count);
      } catch (error) {
        console.error('Error while fetching total images count:', error);
      }
  },[datasetId, label, showIncluded,showOmitted, showPredictionsOnly]);
  
  const fetchImages = useCallback(async () => {
      const query = new Parse.Query(ImageTrainingData);
      query.exists("url");

      query.skip((currentPage - 1) * PAGE_SIZE);

      query.limit(PAGE_SIZE);

      if (confFilter) {
        query.exists(confFilter);
      }

      if (confMinMax && confMinMax.length) {
        if (confMinMax[0]) {
          query.greaterThanOrEqualTo(confFilter, confMinMax[0]);
        }
        if (confMinMax[1]) {
          query.lessThan(confFilter, confMinMax[1]);
        }
      }

      if (sortBy) {
        query.exists(sortBy);
        if (sortDirection && sortDirection === -1) {
          query.descending(sortBy);
        } else {
          query.ascending(sortBy);
        }
      } else {
        query.ascending("createdAt");

      }

      query.equalTo("datasetId", datasetId);
      if (label) {
        if (label==="none"){
          query.doesNotExist("label");
        } else {
          query.equalTo("label", label);
        }
      }
      if (showOmitted) {
        query.equalTo('omit', true);
      }

      if (predictionId && predictionMinMax) {
        if (predictionMinMax?.length === 2) {
          let innerQuery = new Parse.Query("ImagePredictionResult");
          innerQuery.exists(predictionId);
          innerQuery.greaterThanOrEqualTo(predictionId, predictionMinMax[0]);
          innerQuery.lessThanOrEqualTo(predictionId, predictionMinMax[1]);
          query.matchesQuery("predictionData", innerQuery);
        }
      }

      if (showPredictionsOnly) {
        query.exists('predictionData');
      }

      if (showIncluded) {
        query.equalTo('include', true);
      }
    
      // query.equalTo("include", true);

      try {
        const results = await query.find();

        const images = results.map((image) => {
          return {
            url: parseImageToImageKit(
              image.get('url'), true
            ),
            objectId: image.id,
            omit: image.get("omit"),
            included: image.get("include")
          }
        });

        setImages(images);

      } catch (error) {
        console.error('Error while fetching images:', error);
      }
  }, [currentPage, datasetId, label, showOmitted, showIncluded, showPredictionsOnly]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  useEffect(() => {
    fetchDatasetIds();
  }, [fetchDatasetIds]);
  
  useEffect(() => {
    if (datasetId) {
      fetchLabels();
      fetchTotalImages();
      fetchImages();
    }
  }, [datasetId, label, showOmitted, showIncluded, fetchTotalImages, fetchImages, fetchLabels]);

  useEffect(() => {
    const storedSelectedImages = sessionStorage.getItem("selectedImages");
    if (storedSelectedImages) {
      setSelectedImages(new Set(JSON.parse(storedSelectedImages)));
    }
  }, []);

  const openModal = () => {
    setModalDatasetId(datasetId);
    setModalLabel(label);
    setModalLabels(labels);
    setModalOpen(true);
  };

  const clearSelection = () => {
      setSelectedImages(new Set());
      sessionStorage.removeItem("selectedImages");
  }
  
  const confirmAndClearSelection = () => {
    let yes = window.confirm("Are you sure you want to clear the selection?");
    if (yes) clearSelection();
  };

  
  const handleImageSelection = (event, image) => {
    if (event.metaKey || event.ctrlKey) {
      const newSelectedImages = new Set(selectedImages);
  
      if (selectedImages.has(image.objectId)) {
        newSelectedImages.delete(image.objectId);
      } else {
        newSelectedImages.add(image.objectId);
      }
  
      setSelectedImages(newSelectedImages);
      sessionStorage.setItem("selectedImages", JSON.stringify(Array.from(newSelectedImages)));
    }
  };
  

  const calculateProgressPercentage = () => {
    const totalPages = Math.ceil(totalImages / PAGE_SIZE);
    if (totalPages - 1 === 0) {
      return 100;
    } else {
      return ((currentPage - 1) / (totalPages - 1)) * 100;
    }
  };
  

  const handleNextPage = () => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("page", currentPage + 1);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };
  
  const handleDeleteImage = async (imageId) => {
    try {
      let itd = new ImageTrainingData();
      itd.id = imageId;
      itd.set("omit", !itd.get('omit'));
      await itd.save();
      fetchImages();
    } catch (error) {
      console.error('Error while deleting image:', error);
    }
  };
    const handleIncludeImage = async (imageId) => {
    try {
      let itd = new ImageTrainingData();
      itd.id = imageId;
      itd.set("include", !itd.get("include"));
      await itd.save();
      fetchImages();
    } catch (error) {
      console.error('Error while deleting image:', error);
    }
  };

  const handleShowOmittedChange = (e) => {
    const newLabel = e.target.checked;
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("showOmitted", newLabel);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };

  const handleShowIncludedChange = (e) => {
    const newLabel = e.target.checked;
    const urlParams = new URLSearchParams(location.search);
    urlParams.set("showIncluded", newLabel);
    navigate(`${location.pathname}?${urlParams.toString()}`);
  };

  const applyBulkCopy = async () => {
    if (selectedImages.size > 0) {
      try {
        let yes = window.confirm("Are you sure you want to create copies of these images?");
        if (!yes) return;
        const itdIds = Array.from(selectedImages);
        console.log(itdIds);
        let query = new Parse.Query(ImageTrainingData);
        query.containedIn("objectId", itdIds);
        query.limit(5000);
        let originalItds = await query.find();
        console.log(originalItds);
        let toSave = [];
        for (let _itd of originalItds) {
          let itd = new ImageTrainingData();

          let props = _itd.toJSON();
          delete props.objectId;
          console.log(props);
          itd.set(props);

          if (modalDatasetId) {
            itd.set("datasetId", modalDatasetId);
          }
  
          if (modalLabel) {
            itd.set("label", modalLabel);
          }
  
          if (modalOmit) itd.set("omit", modalOmit);
          if (modalInclude) itd.set("include", modalInclude);
          toSave.push(itd);
        }
        console.log(toSave.length);
        await Parse.Object.saveAll(toSave);
        confirmAndClearSelection();
        alert("Successfully saved copies.");
      } catch (error) {
        console.error("Error while applying bulk update:", error);
      }
    }
  };

  const applyBulkDelete = async () => {
    if (selectedImages.size > 0) {
      try {
        let yes = window.confirm("Are you sure you want to delete these images?");
        if (!yes) return;
        const itdIds = Array.from(selectedImages);
        let query = new Parse.Query(ImageTrainingData);
        query.containedIn("objectId", itdIds);
        query.limit(5000);
        let originalItds = await query.find();
        await Parse.Object.destroyAll(originalItds);
        clearSelection();
        alert("Successfully destroyed copies.");
      } catch (error) {
        console.error("Error while applying bulk update:", error);
      }
    }
  };
  
  
  const handleKeyDown = useCallback((event) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'a') {
      event.preventDefault();
      const newSelectedImages = new Set(selectedImages);
  
      images.forEach((image) => {
        newSelectedImages.add(image.objectId);
      });
  
      setSelectedImages(newSelectedImages);
    }
  }, [images, selectedImages]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  

  const applyBulkUpdate = async () => {
    if (selectedImages.size > 0) {
      try {
        let imagesToUpdate = Array.from(selectedImages).map(objectId => {
          const image = new ImageTrainingData();
          image.id = objectId;
  
          if (modalDatasetId) {
            image.set("datasetId", modalDatasetId);
          }
  
          if (modalLabel) {
            image.set("label", modalLabel);
          }
  
          image.set("omit", modalOmit);
          image.set("include", modalInclude);
  
          return image;
        });

        console.log(imagesToUpdate);
  
        try {
          let query = new Parse.Query(ImageTrainingData);
          query.containedIn("objectId", imagesToUpdate.map(itd => itd.id));
          query.select("objectId");
          query.limit(6000);
          let validItds = await query.find();
          validItds = validItds.map(itd => itd.id);
          imagesToUpdate = imagesToUpdate.filter(itd => validItds.indexOf(itd.id) > -1);
          await Parse.Object.saveAll(imagesToUpdate);
          confirmAndClearSelection();
        } catch (e) {
          console.log(e);
        }
        fetchImages(); // Refresh the image grid
        // setSelectedImages(new Set()); // Clear the selection
      } catch (error) {
        console.error("Error while applying bulk update:", error);
      }
    }
    setModalOpen(false);
  };

  const discardModalChanges = () => {
    setModalOpen(false);
  };
  

  return (
    <div className="App">

    <div className="menu-bar">
      <select value={datasetId} onChange={handleDatasetIdChange}>
        <option value="">Select Dataset ID</option>
        {datasetIds.map((id, index) => (
          <option key={index} value={id}>
            {id}
          </option>
        ))}
      </select>
      <select value={label} onChange={handleLabelChange}>
        <option value="">Select Label</option>
        {labels.map((label, index) => (
          <option key={index} value={label}>
            {label}
          </option>
        ))}
      </select>
      <div className="filter-wrapper">
  <label htmlFor="show-omitted">
    <input
      type="checkbox"
      id="show-omitted"
      checked={showOmitted}
      onChange={handleShowOmittedChange}
    />
    Show Omitted
  </label>
  <label htmlFor="show-included">
    <input
      type="checkbox"
      id="show-included"
      checked={showIncluded}
      onChange={handleShowIncludedChange}
    />
    Show Included
  </label>
</div>
      <div className="actions-container">
      {selectedImages.size > 0 && (
        <div>
        <span>{selectedImages.size} selected</span>
        <button className="bulk-update-button" onClick={openModal}>
          Bulk update...
        </button>
        <button className="clear-selection-button" onClick={confirmAndClearSelection}>
          Clear selection
        </button>
        </div>
      )}


      <button className="next-page-button" onClick={handleNextPage}>
        Next Page
      </button>
      </div>
    </div>

        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${calculateProgressPercentage()}%` }}></div>
          <span className="progress-text">{Math.round(calculateProgressPercentage())}% - {totalImages} images</span>
        </div>

<div class="images">
      {images.map((image, index) => (

        <div 
        className={`image-container ${image.omit ? "omitted" : image.included ? "included" : ""} ${
          selectedImages.has(image.objectId) ? "selected" : ""
        }`}
        onClick={(event) => handleImageSelection(event, image)}
        key={image.objectId}>


          <img src={image.url} alt={`Grid item ${index}`} />

          <div className="approve-button" onClick={() => handleIncludeImage(image.objectId)}>
            &#10004;
          </div>

          <button
            className="delete-button"
            onClick={() => handleDeleteImage(image.objectId)}
          >
            X
          </button>
        </div>
      ))}
    </div>

    {isModalOpen && (
      <div className="modal">
        <div className="modal-content">
          <h2>Bulk Update</h2>
          <div>
            <label htmlFor="modal-dataset-id">Dataset ID:</label>
            <select
              id="modal-dataset-id"
              value={modalDatasetId}
              onChange={(e) => {
                if (e.target.value === 'new') {
                  handleCreateNewDataset();
                } else {
                  handleModalDatasetChange(e.target.value);
                }
              }
            }
            >
              <option value="">Select Dataset ID</option>
              {datasetIds.map((datasetId) => (
                <option key={datasetId} value={datasetId}>
                  {datasetId}
                </option>
              ))}
              <option value="new">New dataset...</option>
            </select>
          </div>
          <div>
            <label htmlFor="modal-label">Label:</label>
            <select
              id="modal-label"
              value={modalLabel}
              onChange={(e) => {
                if (e.target.value === 'new') {
                  handleCreateNewLabel();
                } else {
                  setModalLabel(e.target.value);
                }
              }
            }>
              <option value="">Select Label</option>
              {modalLabels.map((label) => (
                <option key={label} value={label}>
                  {label}
                </option>
              ))}
              <option value="new">New label...</option>
            </select>
          </div>
          <div>
            <label htmlFor="modal-omit">Omit:</label>
            <input
              type="checkbox"
              id="modal-omit"
              checked={modalOmit}
              onChange={(e) => setModalOmit(e.target.checked)}
            />
          </div>
          <div>
            <label htmlFor="modal-include">Include:</label>
            <input
              type="checkbox"
              id="modal-include"
              checked={modalInclude}
              onChange={(e) => setModalInclude(e.target.checked)}
            />
          </div>
          <button onClick={applyBulkUpdate}>Apply Bulk Edit</button>
          <button onClick={applyBulkCopy}>Duplicate Images</button>
          <button onClick={applyBulkDelete}>Delete Images</button>
          <button onClick={discardModalChanges}>Discard</button>
        </div>
      </div>
    )}
    </div>
    
  );
}

export default ImagesPage;

