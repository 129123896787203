import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HomePage.css';

import Parse from 'parse';

Parse.initialize(
  "7eAsVaNNrFmJ7lVQU81L5J93HN2625ql"
);

Parse.serverURL = "https://prod.curtsyapp.com/parse";

const ImagePredictionResult = Parse.Object.extend('ImagePredictionResult'); // Replace 'Image' with your Parse class name for images
const ItemPredictionData = Parse.Object.extend('ItemPredictionData'); // Replace 'Image' with your Parse class name for images
const imageKitRoot = "https://ik.imagekit.io/pi9lsweb7gk/";

const parseImageToImageKit = (url,lowres) => {
  let parts = url.split(".com/"),
    filename = parts[parts.length-1],
    dimensions = lowres ? 256 : 512;

  return `${imageKitRoot}/tr:w-${dimensions},h-${dimensions},c-at_max/${filename}`
}

const Score = ({ label, value }) => {
  if (value === null || value === undefined) return null;

  const score = value * 100; // since your scores are 0 to 1, we multiply by 100 to get percentages.
  const scoreClass = score >= 50 ? 'green-score' : 'red-score';

  return (
    <li>
      {label}: <span className={scoreClass}>{score.toFixed(2)}%</span>
    </li>
  );
};


const ImagePredictionItem = ({ item }) => (
  <div className="image-prediction-item">
    <div>
      <img src={item.imageUrl} alt="" />
    </div>
    <div className="scores">
      <ul>
        <Score label="Excess Parts" value={item.excessPartsNoExcess} />
        <Score label="Single Item" value={item.multipleItemsSingle} />
        <Score label="First Image" value={item.firstImageCandidateIsFirstImage} />
        <Score label="Standard Background" value={item.nonStandardBackgroundStandard} />
        <Score label="Crop" value={item.cropDecisionUncropped} />
        <Score label="High Quality Image" value={item.lowQualityImageHighQuality} />
        <Score label="Collage" value={item.collageDetectionNotCollage} />
        <Score label="Overlaid Graphics" value={item.overlaidGraphicsNoOverlaidGraphics} />
        <Score label="Rotation" value={item.imageRotationRt0} />
        {item.needsRotation && (
          <li>
            Rotation Amount:  {item.highestRotationPrediction}, {item.highestRotationDirection}
          </li>
        )}
        </ul>
    </div>
  </div>
);

const fetchImagesForDresses = async(dresses) => {

  const query = new Parse.Query(ImagePredictionResult);
  query.ascending("createdAt");
  query.include("testsPerformedOn");
  query.exists("testsPerformedOn");
  query.containedIn("dress", dresses);

  try {
    const results = await query.find();

    const data = results.map(result => {
      let r0 = result.get("imageRotationRt0"),
        r90 = result.get("imageRotationRt90"),
        r180 = result.get("imageRotationRt180"),
        r270 = result.get("imageRotationRt270"),
        needsRotation = false,
        highestRotationDirection = "",
        highestRotationPrediction = Math.max(...[r0, r90,r180,r270]);

      if (highestRotationPrediction > r0) {
        needsRotation = true;
        if (r90 === highestRotationPrediction) highestRotationDirection = "90 degrees";
        if (r180 === highestRotationPrediction) highestRotationDirection = "180 degrees";
        if (r270 === highestRotationPrediction) highestRotationDirection = "270 degrees";
      }
        
      let obj = {
        id: result.id,
        dressId: result.get("dress").id,
        position: result.get('position'),
        imageUrl: parseImageToImageKit(result.get('testsPerformedOn').get("file").url(),true), // assuming you have this field in Parse
        excessPartsNoExcess: result.get('excessPartsNoExcess'),
        multipleItemsSingle: result.get('multipleItemsSingleItem'),
        firstImageCandidateIsFirstImage: result.get('firstImageCandidateIsFirstImage'),
        nonStandardBackgroundStandard: result.get('nonStandardBackgroundStandard'),
        cropDecisionUncropped: result.get('cropDecisionUncropped'),
        lowQualityImageHighQuality: result.get('lowQualityImagesHighQuality'),
        collageDetectionNotCollage: result.get('collageDetectionNotCollage'),
        overlaidGraphicsNoOverlaidGraphics: result.get('overlaidGraphicsNoOverlaidGraphics'),
        imageRotationRt0: r0,
        needsRotation,
        highestRotationDirection,
        highestRotationPrediction
      }
      console.log(obj);

      return obj;
    });

    const groupedData = data.reduce((acc, current) => {
      let dressId = current.dressId;
      if (!acc[dressId]) {
        acc[dressId] = [current];
      } else {
        acc[dressId].push(current);
      }

      return acc;
    }, {});

    return groupedData;

  } catch(e) {
    throw new Error(`fetchImagesForDress ${e}`)
  }
}

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialPage = Number(searchParams.get('page')) || 0;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);

  const ITEMS_PER_PAGE = 25;

  const fetchData = async (currentPage) => {
    try {

      let query = new Parse.Query(ItemPredictionData);
      query.descending("createdAt");
      query.exists("dress");
      query.exists("imagesLastChecked");

      query.limit(ITEMS_PER_PAGE);
      query.skip(currentPage * ITEMS_PER_PAGE);

      let itemPredictionData = await query.find();
      let dresses = itemPredictionData.map(ipd => ipd.get("dress"));
      let imageData = await fetchImagesForDresses(dresses);

      setData(imageData);

    } catch (e) {
      console.error(`Error while fetching item prediction data error ${e}`);
    }
  };

  const nextPage = () => {
    const newPage = currentPage + 1;
    setCurrentPage(newPage);
    searchParams.set('page', newPage);
    navigate(`${location.pathname}?${searchParams.toString()}`);
    window.scrollTo({ top: 0 });

  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

    return (
      <div className="HomePage">
        <div className="image-prediction-list">
        {Object.entries(data).map(([item, results]) => (
          <div key={item}>
            <h2>{item}</h2>
            {results.sort((a, b) => a.position - b.position).map((result) => (
              <ImagePredictionItem key={result.id} item={result} />
            ))}
          </div>
        ))}
        <button onClick={nextPage}>Next page</button>
      </div>
    </div>
    );
};

export default HomePage;
